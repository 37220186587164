
<i18n locale="th" lang="yaml" >
  page.title : "ไม่พบหน้าจอที่เรียก"

  error.dataNotFound : "ไม่พบข้อมูลในระบบ"
  error.pageNotFound : "ไม่พบหน้าจอที่เรียก"
</i18n>

<template>
  <ErrorPage
    :code-description="codeDescription"
    :location="location">
    <span slot="code">
      4<span class="text-error text-bold">0</span>4
    </span>
  </ErrorPage>
</template>

<script>
import ErrorPage from "@components/ErrorPage.vue"

export default {
  page() {
    return {
      title : this.$t('page.title')
    }
  } ,
  components : {
    ErrorPage,
  } ,
  props : {
    error : {
      type : null,
      default : null
    } ,
    location : {
      type : String,
      default : null
    }
  } ,
  computed: {
    codeDescription() {
      if (this.error)
        return this.$t('error.dataNotFound')
      else
        return this.$t('error.pageNotFound')
    }
  },
}
</script>
