<i18n locale="th" lang="yaml" >
card.in-progress.title : "งานซ่อมที่ค้างอยู่"
card.payment.title : "งานซ่อมค้างจ่าย"
card.recent.title : "งานซ่อมล่าสุด"
card.refer-work.title : งานซ่อมที่ถูกส่งต่อมาให้ {name} (Refer Service)
</i18n>

<template>
	<div class="page page-padding">
		<div v-if="$notEmpty(filter)" class="dashboard-pane service-pane">
			<div class="service-section-header">
				<label>{{ $t(`card.${titleName}.title`, { name: currentCompany.name }) }}</label>
			</div>
			<div class="service-section-content">
				<MyCardList
					:num-skeletons="3"
					:empty="selectedServiceDataList.length === 0"
					class="mycard-list-grid service-progess-list"
					:loading="loading">
					<ServiceLiteCard slot="skeleton" :loading="true" />
					<ServiceLiteCard v-for="serviceData of selectedServiceDataList" :key="serviceData.id" :service-data="serviceData" />
				</MyCardList>
			</div>
		</div>
		<div v-else>
			<Error404 />
		</div>
	</div>
</template>

<script>
import ApiError from '@/src/utils/errors/ApiError'
import axios from 'axios'
import ServiceLiteCard from '@components/service/ServiceLiteCard.vue'
import MyCardList from '@components/common/MyCardList.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import Error404 from '../errors/Error404.vue'
import { SERVICE_STATUS, MODE, PAYMENT_STATUS } from '@utils/serviceUtil'

const MODE_LIST = {
	'in-progress': {
		filter: {
			mode: MODE.STATUS_IN_PROGRESS,
		},
	},
	payment: {
		filter: {
			statusList: [SERVICE_STATUS.STATUS_CLOSE],
			paymentStatusList: [PAYMENT_STATUS.STATUS_NEW, PAYMENT_STATUS.STATUS_IN_PROGRESS],
		},
	},
	recent: {
		filter: {
			statusList: [SERVICE_STATUS.STATUS_CLOSE],
			paymentStatusList: [PAYMENT_STATUS.STATUS_COMPLETE],
		},
	},
	'refer-work': {
		filter: {
			statusList: [SERVICE_STATUS.STATUS_CLOSE],
			mode: MODE.WAIT_REFER,
		},
	},
}

export default {
	components: {
		MyCardList,
		ServiceLiteCard,
		Error404,
	},
	mixins: [LoginInfoMixin],
	data() {
		return {
			currentMode: this.$route.params.mode,
			selectedServiceDataList: [],
			loading: false,
		}
	},
	computed: {
		filter() {
			return MODE_LIST[this.currentMode]?.filter
		},
		titleName() {
			return this.$route.params.mode
		},
	},
	mounted() {
		if (this.$notEmpty(this.filter)) {
			if (this.currentMode === 'refer-work') {
				this.filter.serviceReferToCompanyId = this.currentCompany.id
			}
			this.fetchData()
		}
	},
	methods: {
		fetchData() {
			this.loading = true
			const search = {
				pageSize: -1,
				currentPage: 1,
			}
			axios
				.get('/api/services/search', { params: { search, filter: this.filter } })
				.then((response) => {
					this.selectedServiceDataList = response.data.data.services
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
}
</script>

<style lang="less">
@import '../../components/dashboard/dashboard.less';
.service-section-header {
	padding-bottom: 4px;
	margin-bottom: 8px;
	label {
		font-family: @font-family-title;
		font-size: 1.2em;
		margin-right: 5px;
	}
	.service-section-action {
		text-decoration: underline dashed;
	}
}
</style>
